import { playMedia } from "../../../../../assets/js/utils/playPauseMedia";

declare var Waypoint: any;
declare var MD_WIDTH: number;

// video state classes
const PLAYING_CLASS = 'is-playing';
const FOCUSED_CLASS = 'is-focused';
const ENDED_CLASS = 'is-ended';

function initVideo(el: HTMLElement) {
  // helper functions
  //-----------------

  /*
    show video controls on mouseover
    hides on mouseout
  */
  function handleControls(video: HTMLVideoElement) {
    video.addEventListener('mouseover', () => {
      video.setAttribute('controls', 'controls');
    });

    video.addEventListener('mouseout', () => {
      video.removeAttribute('controls');
    });
  }

  /*
    gives users a play button
    hides on play and replaced
    by replay button after video ends
  */
  function handlePlay(
    videoPlayControl: HTMLElement,
    videoContainer: HTMLElement,
    video: HTMLVideoElement,
  ) {
    videoPlayControl.addEventListener('click', () => {
      playMedia(video);
      if (window.innerWidth >= MD_WIDTH) {
        video.focus();
      }
      video.setAttribute('controls', 'controls');
      video.setAttribute('tabindex', '0');
      videoPlayControl.setAttribute('tabindex', '-1');
      videoContainer.classList.add(PLAYING_CLASS);
      videoContainer.classList.remove(ENDED_CLASS);
    });
  }

  /*
    watches for video end, shows a
    button to replay video. Replay button will
    play video and hide replay controls.
  */
  function handleReplay(
    video: HTMLVideoElement,
    videoContainer: HTMLElement,
    videoReplayControl: HTMLElement,
  ) {
    video.addEventListener('ended', () => {
      video.currentTime = 0;
      video.removeAttribute('controls');
      video.setAttribute('tabindex', '-1');
      videoReplayControl.setAttribute('tabindex', '0');
      videoContainer.classList.add(ENDED_CLASS);
      videoContainer.classList.remove(PLAYING_CLASS);
    });

    videoReplayControl.addEventListener('click', () => {
      playMedia(video);
      if (window.innerWidth >= MD_WIDTH) {
        video.focus();
      }
      video.setAttribute('controls', 'controls');
      video.setAttribute('tabindex', '0');
      videoReplayControl.setAttribute('tabindex', '-1');
      videoContainer.classList.add(PLAYING_CLASS);
      videoContainer.classList.remove(ENDED_CLASS);
    });
  }

  // video behavior functions
  //-----------------

  // default
  function playDefault(
    video: HTMLVideoElement,
    videoContainer: HTMLElement,
    videoReplayControl: HTMLElement,
    videoPlayControl: HTMLElement,
    shouldHandleControls: boolean = true,
  ) {
    if (shouldHandleControls) {
      handleControls(video);
    }
    handleReplay(video, videoContainer, videoReplayControl);
    handlePlay(videoPlayControl, videoContainer, video);
  }

  // play on hover
  function playOnHover(
    video: HTMLVideoElement,
    videoContainer: HTMLElement,
    videoReplayControl: HTMLElement,
  ) {
    video.addEventListener('mouseover', () => {
      if (video.paused) {
        playMedia(video);
        videoContainer.classList.add(PLAYING_CLASS);
      }
    });
    video.addEventListener('mouseout', () => {
      if (!video.paused) {
        video.pause();
        videoContainer.classList.remove(PLAYING_CLASS);
      }
    });
    video.addEventListener('click', (e: MouseEvent) => {
      e.preventDefault();
      video.focus();
    });

    // Allow replaying if user is still in viewport
    handleReplay(video, videoContainer, videoReplayControl);
  }

  function setupPlayPauseButtons(
    video: HTMLVideoElement,
    videoControls: HTMLElement,
  ) {
    video.addEventListener('click', () => {
      if (video.paused) {
        playMedia(video);
        video.removeAttribute('controls');
      } else {
        video.pause();
        video.removeAttribute('controls');
      }
    });
    videoControls.addEventListener('click', () => {
      if (video.paused) {
        playMedia(video);
        video.removeAttribute('controls');
      } else {
        video.pause();
        video.removeAttribute('controls');
      }
    });
  }

  function setupPlayPauseEvents(
    video: HTMLVideoElement,
    playButton: HTMLElement,
    pauseButton: HTMLElement,
  ) {
    video.addEventListener('play', () => {
      playButton.style.display = 'none';
      pauseButton.style.display = 'flex';
    });
    video.addEventListener('pause', () => {
      playButton.style.display = 'flex';
      pauseButton.style.display = 'none';
    });
  }

  function bindAccessibilityPause(video: HTMLVideoElement) {
    video.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.code === 'Space') {
        e.preventDefault();
        if (video.paused) {
          playMedia(video);
        } else {
          video.pause();
        }
      }
    });
  }

  // Play in view video behavior
  function playInView(
    video: HTMLVideoElement,
    videoContainer: HTMLElement,
    videoReplayControl: HTMLElement,
    shouldHandleControls: boolean = true,
  ) {
    video.currentTime = 0;

    // Play video when 75% is visible in viewport
    // DOWN
    // eslint-disable-next-line no-unused-vars
    const waypointDown = new Waypoint({
      element: video,
      offset: '75%',
      handler: (direction: string) => {
        if (direction === 'down') {
          playMedia(video);
          video.setAttribute('tabindex', '0');
          videoReplayControl.setAttribute('tabindex', '-1');
          videoContainer.classList.add(PLAYING_CLASS);
          videoContainer.classList.remove(ENDED_CLASS);
          if (shouldHandleControls) {
            handleControls(video);
          }
        }
      },
    });
    // UP
    // eslint-disable-next-line no-unused-vars
    const waypointUp = new Waypoint({
      element: video,
      offset: '-75%',
      handler: (direction: string) => {
        if (direction === 'up') {
          playMedia(video);
          video.setAttribute('tabindex', '0');
          videoReplayControl.setAttribute('tabindex', '-1');
          videoContainer.classList.add(PLAYING_CLASS);
          videoContainer.classList.remove(ENDED_CLASS);
          if (shouldHandleControls) {
            handleControls(video);
          }
        }
      },
    });

    // Allow replaying if user is still in viewport
    handleReplay(video, videoContainer, videoReplayControl);
  }

  const videoContainer = el;
  const video = el.querySelector('video');
  if (!(video instanceof HTMLVideoElement)) return;

  const videoType = video.getAttribute('data-video-type');
  const controlsType = video.getAttribute('data-controls-type');

  const videoReplayControl = el.querySelector('.video-replay-control');
  const videoPlayControl = el.querySelector('.video-play-control');
  const videoControls = el.querySelector(
    '.content-video__video-control-overlay',
  );
  const playButton = el.querySelector(
    '.content-video__video-control-overlay__play',
  );
  const pauseButton = el.querySelector(
    '.content-video__video-control-overlay__pause',
  );
  const pressSpaceToPause = el.querySelector('.js-pause-button');

  if (pressSpaceToPause instanceof HTMLButtonElement) {
    pressSpaceToPause.addEventListener('click', (e) => {
      e.preventDefault();
      if (video.paused) {
        playMedia(video);
      } else {
        video.pause();
      }
    });
  }

  if (controlsType === 'simple-controls') {
    if (!(videoControls instanceof HTMLElement)) {
      return;
    }
    if (!(playButton instanceof HTMLElement)) {
      return;
    }
    if (!(pauseButton instanceof HTMLElement)) {
      return;
    }
    setupPlayPauseButtons(video, videoControls);
    setupPlayPauseEvents(video, playButton, pauseButton);
    bindAccessibilityPause(video);
  }

  if (videoReplayControl instanceof HTMLElement) {
    const isSimpleControls = controlsType === 'simple-controls';
    // Decide which video type to render
    switch (videoType) {
      case 'playOnHover':
        if (window.innerWidth <= MD_WIDTH) {
          playInView(video, videoContainer, videoReplayControl, !isSimpleControls);
        } else {
          playOnHover(video, videoContainer, videoReplayControl);
        }
        break;
      case 'playInView':
        playInView(video, videoContainer, videoReplayControl, !isSimpleControls);
        break;
      default:
        if (videoPlayControl instanceof HTMLElement) {
          playDefault(
            video,
            videoContainer,
            videoReplayControl,
            videoPlayControl,
            !isSimpleControls,
          );
        }
    }

    // ACCESSIBILITY
    video.addEventListener('focus', () => {
      video.setAttribute('controls', 'controls');
      if (video === document.activeElement) {
        el.classList.add(FOCUSED_CLASS);
      }
      // add tooltip if focused video plays
      video.addEventListener('play', () => {
        if (video === document.activeElement) {
          el.classList.add(PLAYING_CLASS);
        }
      });
      // remove tooltip when video ends or pauses
      video.addEventListener('pause', () => {
        el.classList.remove(PLAYING_CLASS);
      });
      video.addEventListener('ended', () => {
        el.classList.remove(PLAYING_CLASS);
      });
      // show tooltip on already playing videos
      if (!video.paused) {
        el.classList.add(PLAYING_CLASS);
      }
      el.classList.remove(ENDED_CLASS);
    });
    video.addEventListener('blur', () => {
      video.removeAttribute('controls');
      el.classList.remove(FOCUSED_CLASS);
    });
  }
}

const videos: Array<HTMLElement> = Array.from(
  document.querySelectorAll('.js-video'),
);
videos.forEach(initVideo);
